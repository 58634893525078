import React, { useEffect, useState, useRef } from "react";

export default function Autocomplete({
  name,
  searchParam,
  placeholder,
  list,
  selectedObject,
  isError=false,
  required = false 
}) {
  const [singlebtn, setSinglebtn] = useState(false);
  const [searchList, setSearchList] = useState(list);
  const [selectedItem, setSelectedItem] = useState(
    placeholder ? placeholder : `Select ${name}`
  );
  
  const [searchInput, setSearchInput] = useState(""); // Store the search input
  const ref = useRef(); // Ref to detect clicks outside

  const filterList = (value) => {
    setSearchInput(value.target.value);
    const filteredList = list.filter((item) =>
      item[searchParam]
        .toLowerCase()
        .includes(value.target.value.toLowerCase())
    );
    setSearchList(filteredList); // Update the search list based on search
  };

  useEffect(() => {
    setSearchList(list); // Reset the list whenever the main list changes
  }, [list]);

  useEffect(() => {
    setSelectedItem(placeholder ? placeholder : `Select ${name}`);
  }, [placeholder]);

  // use effect to show error if isError is true
  useEffect(() => {
    if (isError) {
      ref.current.style.border = "1px solid red";
    } else {
      ref.current.style.border = "1px solid #ced4da";
    }
  }, [isError]);

  // Close the dropdown on clicking outside
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (ref.current && !ref.current.contains(event.target)) {
        setSinglebtn(false);
      
      }
    };
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [ref]);

  const clearSelection = () => {
    
    setSelectedItem(placeholder ? placeholder : `Select ${name}`);
    setSearchList(list);
    setSearchInput(""); // Clear the search input
    selectedObject(null); // Clear the selected object
      
  };

  return (
    <div className="" ref={ref}>
      <div className="d-flex">
        <button
          className="auto-complete-btn"
          type="button"
          onClick={() => {
            setSinglebtn(!singlebtn);
          }}
          style={required && !selectedItem ? {border: '1px solid red'} : {}}
          aria-required={required} 
        >
          {selectedItem}
        </button>
        {/* Clear button */}
        <button
          className="btn btn-sm btn-primary"
          type="button"
          onClick={clearSelection}
          style={{ marginLeft: "10px" }}
        >
          x
        </button>
      </div>
      <ul className={singlebtn ? "auto-complete-menu" : "d-none"} style={{ maxHeight: "200px", overflowY: "auto" }}>
        <li className="p-0" style={{
          position: "sticky",
          top: "0",
          backgroundColor: "white",
          zIndex: "1",
        }}>
          <input
            onChange={(e) => {
              filterList(e);
            }}
            className="form-control"
            type="text"
            value={searchInput}
            placeholder={`Search a ${name}`}
          />
        </li>
        {/* Show all items but with scrollable container */}
        {searchList?.map((item, index) => (
          <li
            onClick={() => {
              setSelectedItem(item[searchParam]);
              selectedObject(item);
              setSinglebtn(false);
            }}
            key={index}
          >
            {item[searchParam]}
          </li>
        ))}
      </ul>
    </div>
  );
}
