import React, { useEffect, useState } from "react";
import { MDBDataTable } from "mdbreact";
import { Container, Row, Col, Button } from "reactstrap";
import instance from "base_url";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";



export default function SupportTypes() {
  const { t } = useTranslation();
  const [supportTypes, setSupportTypes] = useState(null);

  // get Support Types
  const getSupportTypes = async () => {
    try {
      const { data } = await instance.get("/support-type/");
      setSupportTypes(data.results);
    } catch (e) {
      console.error(e);
    }
  };

  useEffect(() => {
    getSupportTypes();
  }, []);

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <div className="page-title-box">
            <Row className="align-items-center mb-3">
              <Col md={8}>
                <h6 className="page-title">{t("Support Types")}</h6>
              </Col>
              <Col md="4">
                <div className="float-end d-none d-md-block">
                  <Link to="/create-support-type"
                    className="btn btn-primary dropdown-toggle waves-effect waves-light"
                  >
                   {t("Create Support Type")}
                  </Link>
                </div>
              </Col>
            </Row>
            <Row>
              <Col>
                {supportTypes ? (
                  <div className="table-responsive">
                    <table className="table table-bordered table-hover">
                      <thead className="bg-dark">
                        <tr>
                          <th scope="col">{t("Support Name")}</th>
                          <th scope="col">{t("Support Kind")}</th>
                          <th scope="col">{t("Description")}</th>
                          <th scope="col" className="text-center">
                          {t("Action")}
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {supportTypes.map((item, index) => (
                          <tr key={index}>
                            <td>{item.name}</td>
                            <td>{item.kind}</td>
                            <td>{item.description}</td>
                            <td className="text-center">
                              <Link to={`/support-type/${item.id}`} state={item.id} className="btn btn-primary">{t("Details")}</Link>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                ) : (
                  <></>
                )}
              </Col>
            </Row>
          </div>
        </Container>
      </div>
    </React.Fragment>
  );
}
