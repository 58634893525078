import React, { useEffect, useState } from "react"
import instance from "base_url"
import { Link } from "react-router-dom"
import { Container, Row, Col, Button } from "reactstrap"
import { useTranslation } from "react-i18next";


export default function SupportCriteria() {
  const [supportCriterias, setSupportCriterias] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [errorOccurred, setErrorOccurred] = useState(false);
  const { t } = useTranslation();

  // Get Support Criteria
  const getSupportCriterias = async () => {
    setIsLoading(true)
    try {
      const { data } = await instance.get("/support-criteria/")
      setSupportCriterias(data.results)
      setIsLoading(false)
      setErrorOccurred(false)
    } catch (e) {
      setErrorOccurred(true)
      console.error("ERROR: ", e)
    }
  }

  useEffect(() => {
    getSupportCriterias()
  }, [])

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          {errorOccurred ? 
          <div>
            <p>{t("An Error Occurred!")}</p>
            <Button onClick={()=>{getSupportCriterias()}}>{t("Try again")}</Button>
          </div>
          :
          isLoading ?
          <p>{t("Loading...")}</p>
          :
          <div className="page-title-box">
            <Row className="align-items-center mb-3">
              <Col md={8}>
                <h6 className="page-title">{t("Support Criterias")}</h6>
              </Col>

              <Col md="4">
                <div className="float-end d-none d-md-block">
                  <Link to="/support-criteria/new" className="btn btn-primary">
                  {t("Create Support Criteria")}
                  </Link>
                </div>
              </Col>
            </Row>
            <Row>
              <Col>
                <div className="table-responsive">
                  <table className="table table-bordered table-hover">
                    <thead className="bg-dark">
                      <tr>
                        <th scope="col">{t("Title")}</th>
                        <th scope="col" className="text-center">{t("Action")}</th>
                      </tr>
                    </thead>
                    <tbody>
                      {
                        supportCriterias.map((sc, index)=>(
                          <tr key={index}>
                            <td>{sc.title}</td>
                            <td className="text-center"><Link to={`/support-criteria/${sc.id}`} state={sc} className='btn btn-sm btn-primary'>{t("Details")}</Link></td>
                          </tr>
                        ))
                      }
                    </tbody>
                  </table>
                </div>
              </Col>
            </Row>
          </div>
          }
        </Container>
      </div>
    </React.Fragment>
  )
}
