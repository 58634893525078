import React, { useEffect, useState } from "react";
import instance from "base_url";
import Autocomplete from "components/Common/Autocomplete";
import { useParams, useNavigate } from "react-router-dom";
import { Container, Row, Col, Button } from "reactstrap";
import moment from "moment";
import { useSearchParams } from "react-router-dom";
import { withTranslation } from "react-i18next"; 
import MapAddress from "components/Common/MapAddress";
import { validateRequiredFields, setErrorFn } from '../Utility/Functions';
import Alert from "components/Common/Alert";


// import style.css from current directory
import "./style.css";

const defaultIndividual = {
  first_name: "",
  last_name: "",
  national_id: "",
  date_of_birth: "",
  place_of_birth: "",
  job_title: "",
  salary: "",
  gender: "",
  partner_relationship: "",
  partner_relationship_status: "",
  mobile_number: "",
  email: "",
  address: "",
  location: "",
  family: null,
  mother: null,
  father: null,
  partner_id: null,
  status: "alive",
  is_head_of_family: false,
  stay_with_family: true,
  is_draft: false,
  notes: "",
  is_working: false,
};

 function CreateOrEditIndividual( { t } ) {
  const [alert, setAlert] = useState({ show: false, message: '', description: '', type: '' });
  const [individual, setIndividual] = useState(defaultIndividual);
  const [isFormSubmitted, setIsFormSubmitted] = useState(false);
  const navigate = useNavigate();
  const { id } = useParams();
  let [searchParams, setSearchParams] = useSearchParams();
  const familyId = searchParams.get("family");
  const [selectedFamily, setSelectedFamily] = useState({});
  const [dataSaved, setDataSaved] = useState(false);
  const [locationLoading, setLocationLoading] = useState(false);
  const [isSaving, setIsSaving] = useState(false);
  const [errorOccurred, setErrorOccurred] = useState(false);
  const [error, setError] = useState(null);
  const [showMap, setShowMap] = useState(false);
  const [headOfFamily, setHeadOfFamily] = useState(null);

  // get visit id from query params (if the page is visited from a visits page)
  const visitId =
    new URLSearchParams(window.location.search).get("visit") ||
    individual?.last_updated_by_visit;

  // Get individual Details
  const getIndividualDetails = async () => {
    try {
      const { data } = await instance.get(`/individuals/${id}/`);
      data.mobile_number = data.mobile_number || data.family_mobile_number;
      setIndividual(data);
      getFamilies(data.family);
    } catch (e) {
      console.error(e);
      setErrorOccurred(true);
      setError(e.message);
    }
  };

  // Get Families list
  const getFamilies = async (familyId) => {
    try {
      const { data } = await instance.get(`/families/${familyId}/`);
      // Set selected family if you are targeting details page
      setSelectedFamily(data);
      const headOfFamily = data.individuals.find( individual => individual.is_head_of_family);
      if (headOfFamily) {
        setHeadOfFamily(headOfFamily);
      }
    } catch (e) {
      console.error(e);
      // setErrorOccurred(true);
      // setError(e.message);
    }
  };

  useEffect(() => {
    // set default values from family head if exists else one of family
    if (id && id !== "new") {
      getIndividualDetails();
    }
    getFamilies(familyId);

    const headOfFamily = selectedFamily?.individuals?.find(
      (individual) => individual.is_head_of_family
    );
    if (headOfFamily) {
      setIndividual((prevIndividual) => ({
        ...prevIndividual,
        mobile_number: headOfFamily.mobile_number,
        email: headOfFamily.email,
        address: headOfFamily.address,
        location: headOfFamily.location,
      }));
    }
  }, [id]);

  const updateServerParams = (value, param) => {
    // check type of value if boolean or not
    if (typeof value === "boolean") {
      setIndividual((prevIndividual) => ({ ...prevIndividual, [param]: value }));
    } else {
      setIndividual((prevIndividual) => ({ ...prevIndividual, [param]: value ? value : null }));
    }
  };

  const saveIndividual = async () => {
    setIsFormSubmitted(true); // to show required fields
    setIsSaving(true);

    const isInvalidForm = validateRequiredFields();
    if (isInvalidForm) {
      setAlert({ show: true, message: 'Error', description: 'Some fields are missing!', type: 'error' });
      setIsSaving(false);
      return
    }

    const payload = {
      ...individual,
      family: familyId,
      last_updated_by_visit: visitId,
    };
    try {
      await (id && id !== "new"
        ? instance.put(`/individuals/${id}/`, payload)
        : instance.post("/individuals/", payload));
      navigate(-1);
      setDataSaved(true);
      // setTimeout(() => {
      //   navigate(-1);
      // }, 1500);
    } catch (e) {
      console.error(e);
      setAlert({ show: true, message: 'Error', description: 'Failed to save individual!', type: 'error' });
      setErrorFn(e, setErrorOccurred, setError);
    }
    setIsSaving(false);
  };

  // Get current location
  const getLocation = () => {
    setLocationLoading(true);
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(showPosition);
    } else {
      alert(t("Geolocation is not supported by this browser."));
    }
  };

  const showPosition = (position) => {
    const newIndividual = { ...individual };
    newIndividual.location = `${position.coords.latitude},${position.coords.longitude}`;
    setIndividual(newIndividual);
    setLocationLoading(false);
  };

  const handleStringInput = (e, param) => {
    const value = e.target.value;
    // Use regex to allow only letters and spaces
    const regex = /^[^\d\u0660-\u0669\u06F0-\u06F9]*$/;
  
    if (regex.test(value)) {
      updateServerParams(value, param);
    }
  };

  const handleLocationChange = (latlng, address) => {
    updateServerParams(latlng, "location");
    updateServerParams(address, "address");
  };

  const getPartnerOptions = () => {
    if (individual && individual.gender == 'male') {
      return selectedFamily?.individuals?.filter(x => x.gender == 'female');
    } else if (individual && individual.gender == 'female') {
      return selectedFamily?.individuals?.filter(x => x.gender == 'male');
    } else {
      return selectedFamily?.individuals;
    }
  }

  return (
    <React.Fragment>
      <div className="page-content">
        {alert.show && (
  <Alert 
    message={alert.message} 
    description={alert.description} 
    type={alert.type} 
    onClose={() => setAlert({ show: false, message: '', description: '', type: '' })} 
  />
)}

        <Container fluid>
          {errorOccurred && (
            <div className="alert alert-danger" role="alert">
              {error}
            </div>
          )}
          <div className="page-title-box">
            <Row className="align-items-center mb-3">
              <Col md={8}>
                <h6 className="page-title">
                {id && id !== "new" ? t("Edit Individual") : t("Create Individual")}
                </h6>
              </Col>
            </Row>

            <div className="form-page-container">
         
              {/* First name */}
              <div className="row mb-4">
                <div className="col-2 align-content-center">
                  <p className="m-0">
                    <strong>{t("First name")}</strong>
                  </p>
                </div>
                <div className="col-4">
                  <input
                    className="form-control"
                    placeholder={t("Add First Name")}
                    value={individual.first_name}
                    onChange={(e) => handleStringInput(e, "first_name")}
                    required={true}
                  />
                </div>
              </div>

              {/* Last name */}
              <div className="row mb-4">
                <div className="col-2 align-content-center">
                  <p className="m-0">
                    <strong>{t("Last name")}</strong>
                  </p>
                </div>
                <div className="col-4">
                  <input
                    className="form-control"
                    placeholder={t("Add Last Name")}
                    value={individual.last_name}
                    onChange={(e) => handleStringInput(e, "last_name")}
                    required={true}
                  />
                </div>
              </div>

              {/* Father | This field will not be shown in individual create case 
              {id === "new" ? (
                <></>
              ) : (*/}
                <div className="row mb-4">
                  <div className="col-2 align-content-center">
                    <p className="m-0">
                      <strong>{t("Father")} </strong>
                    </p>
                  </div>
                  <div className="col-4 ">
                    <Autocomplete
                      name="Father"
                      searchParam="name"
                      placeholder={individual?.father_name || t("Select a father")}
                      list={selectedFamily?.individuals?.filter(x =>  x.gender == 'male')}
                      selectedObject={(value) => {
                        updateServerParams(value?.id, "father");
                      }}
                    />
                  </div>
                </div>
              {/* )} */}

              {/* Mother | This field will not be shown in individual create case */}
              {/* {id === "new" ? (
                <></>
              ) : ( */}
                <div className="row mb-4">
                  <div className="col-2 align-content-center">
                    <p className="m-0">
                      <strong>{t("Mother")}</strong>
                    </p>
                  </div>
                  <div className="col-4 ">
                    <Autocomplete
                      name="Mother"
                      searchParam="name"
                      placeholder={individual?.mother_name || t("Select a mother")}
                      list={selectedFamily?.individuals?.filter(x => x.gender == 'female')}
                      selectedObject={(value) => {
                        updateServerParams(value?.id, "mother");
                      }}
                    />
                  </div>
                </div>
              {/* )} */}

              {/* National Id */}
              <div className="row mb-4">
                <div className="col-2 align-content-center">
                  <p className="m-0">
                    <strong>{t("National Id")}</strong>
                  </p>
                </div>
                <div className="col-4">
                  <input
                    className="form-control"
                    placeholder={t("Add National Id")}
                    type="number"
                    inputMode="numeric"
                    pattern="\d*"
                    min="0"
                    value={individual.national_id}
                    onChange={(e) => {
                      updateServerParams(e.target.value, "national_id");
                    }}
                    required={true}
                  />
                </div>
              </div>

              {/* Date of birth */}
              <div className="row mb-4">
                <div className="col-2 align-content-center">
                  <p className="m-0">
                    <strong>{t("Date of birth")}</strong>
                  </p>
                </div>
                <div className="col-4">
                  <input
                    className="form-control"
                    type="date"
                    max="9999-12-31"
                    placeholder={t("Add Date of birth")}
                    value={
                      individual.date_of_birth
                        ? moment(individual.date_of_birth).format("YYYY-MM-DD")
                        : ""
                    }
                    onChange={(e) => {
                      updateServerParams(e.target.value, "date_of_birth");
                    }}
                    required={true}

                  ></input>
                </div>
              </div>

              {/* Place of birth */}
              <div className="row mb-4">
                <div className="col-2 align-content-center">
                  <p className="m-0">
                    <strong>{t("Place of birth")}</strong>
                  </p>
                </div>
                <div className="col-4">
                  <input
                    className="form-control"
                    placeholder={t("Add Place of birth")}
                    value={individual.place_of_birth}
                    onChange={(e) => handleStringInput(e, "place_of_birth")}
                    required={true}

                  />
                </div>
              </div>

              {/* is working */}
              <div className="row mb-4">
                <div className="col-2 align-content-center">
                  <p className="m-0">
                    <strong>{t("Is Working")}</strong>
                  </p>
                </div>
                <div className="col-4">
                  <div className="form-check form-switch">
                    <input
                    className={`form-check-input ` } 
                      checked={individual.is_working}
                      type="checkbox"
                      role="switch"
                      onChange={(e) => {
                        updateServerParams(
                          e.target.checked,
                          "is_working"
                        );
                      }}
                    />
                  </div>
                </div>
              </div>

              {individual.is_working ?
              <>
                <div className="row mb-4">
                  <div className="col-2 align-content-center">
                    <p className="m-0">
                      <strong>{t("Job title")}</strong>
                    </p>
                  </div>
                  <div className="col-4">
                    <input
                      className="form-control"
                      placeholder={t("Add job title")}
                      value={individual.job_title}
                      onChange={(e) => handleStringInput(e, "job_title")}
                      required={true}

                    />
                  </div>
                </div>
                <div className="row mb-4">
                  <div className="col-2 align-content-center">
                    <p className="m-0">
                      <strong>{t("Salary")}</strong>
                    </p>
                  </div>
                  <div className="col-4">
                    <input
                      className="form-control"
                      placeholder={t("Add salary")}
                      type="number"
                      inputMode="numeric"
                      pattern="\d*"
                      min="0"
                      value={individual.salary}
                      onChange={(e) => {
                        updateServerParams(e.target.value, "salary");
                      }}
                      required={true}

                    />
                  </div>
                </div> </> : <></>}

              {/* Gender */}
              <div className="row mb-4">
                <div className="col-2 align-content-center">
                  <p className="m-0">
                    <strong>{t("Gender")}</strong>
                  </p>
                </div>
                <div className="col-4">
                  <select
                    className={`form-control form-select ${isFormSubmitted && !individual.gender ? 'is-invalid' : ''}`}        
                    value={individual.gender}
                    onChange={(e) => {
                      updateServerParams(e.target.value, "gender");
                    }}
                    required={true}
                  >
                    <option>{t("Select a gender")}</option>
                    <option value="male">{t("Male")}</option>
                    <option value="female">{t("Female")}</option>
                    <option value="other">{t("Other")}</option>
                  </select>
                </div>
              </div>

              {/* Partner relation status */}
              <div className="row mb-4">
                <div className="col-2 align-content-center">
                  <p className="m-0">
                    <strong>{t("Partner relationship")}</strong>
                  </p>
                </div>
                <div className="col-4">
                  <select
                   className={`form-control form-select ${isFormSubmitted && !individual.gender ? 'is-invalid' : ''}`}
                    value={individual.partner_relationship}
                    onChange={(e) => {
                      updateServerParams(
                        e.target.value,
                        "partner_relationship"
                      );
                    }}
                    required={true}

                  >
                    <option>{t("Select a status")}</option>
                    <option value="single">{t("Single")}</option>
                    <option value="married">{t("Married")}</option>
                    <option value="divorced">{t("Divorced")}</option>
                    <option value="widow">{t("Widow")}</option>
                    <option value="engage">{t("Engage")}</option>
                    {/* TODO: add partnership status: good, tense, good tense */}
                    {/* <option value="good">Good</option>
                    <option value="tense">Tense</option>
                    <option value="good_tense">Good Tense</option> */}
                    <option value="extra_marital">{t("Extra Marital")}l</option>
                    <option value="separation">{t("Separation")}</option>
                  </select>
                </div>
              </div>

              {["married", "engage", "widow", "divorced"].includes(individual.partner_relationship) ? 
              <>
                <div className="row mb-4">
                  <div className="col-2 align-content-center">
                    <p className="m-0">
                      <strong>{t("Partner relationship status")}</strong>
                    </p>
                  </div>
                  <div className="col-4">
                    <select
                      className="form-control form-select"
                      value={individual.partner_relationship_status}
                      onChange={(e) => {
                        updateServerParams(
                          e.target.value,
                          "partner_relationship_status"
                        );
                      }}
                      required={true}

                    >
                      <option>{t("Select a status")}</option>
                      <option value="good">{t("Good")}</option>
                      <option value="tense">{t("Tense")}</option>
                      <option value="good_tense">{t("Good Tense")}</option>
                    </select>
                  </div>
                </div> 
                <div className="row mb-4">
                <div className="col-2 align-content-center">
                  <p className="m-0">
                    <strong>{t("Partner")}</strong>
                  </p>
                </div>
                <div className="col-4 ">
                  <Autocomplete
                    name={t("Partner")}
                    searchParam="name"
                    placeholder={individual.partner_name || t("Select a partner")}
                    // placeholder={individual.partner_name || t("Select a partner")}
                    list={getPartnerOptions()}
                    selectedObject={(value) => {
                      updateServerParams(value?.id, "partner_id");
                    }}
                    required={true}

                  />
                </div>
              </div>
              </>
                : <></> }

              {/* Mobile Number */}
              <div className="row mb-4">
                <div className="col-2 align-content-center">
                  <p className="m-0">
                    <strong>{t("Mobile Number")}</strong>
                  </p>
                </div>
                <div className="col-4">
                  <input
                    className="form-control"
                    placeholder={t("Add mobile number")}
                    type="number"
                    inputMode="numeric"
                    pattern="\d*"
                    min="0"
                    value={individual.mobile_number || headOfFamily?.mobile_number}
                    onChange={(e) => {
                      updateServerParams(e.target.value, "mobile_number");
                    }}
                    required={true}

                  />
                </div>
              </div>

              {/* E-mail */}
              <div className="row mb-4">
                <div className="col-2 align-content-center">
                  <p className="m-0">
                    <strong>{t("E-mail")}</strong>
                  </p>
                </div>
                <div className="col-4">
                  <input
                    className="form-control"
                    placeholder={t("Add E-mail")}
                    value={individual.email || headOfFamily?.email}
                    onChange={(e) => {
                      updateServerParams(e.target.value, "email");
                    }}
                    required={true}
                  />
                </div>
              </div>

              {/* Address */}
              <div className="row mb-4 form-address">
                <div className="col-2 align-content-center">
                  <p className="m-0">
                    <strong>{t("Address")}</strong>
                  </p>
                </div>
                <div className="col-6 d-flex">
                  <input
                    className="form-control"
                    placeholder={t("Add address")}
                    value={individual.address || headOfFamily?.address}
                    onChange={(e) => {
                      updateServerParams(e.target.value, "address");
                    }}
                    required={true}
                  />
                  <Button color="primary" className="mt-2" style={{marginLeft: 10}} onClick={() => {
                    setShowMap(true)
                    // wait for the map to show up then scroll to the map
                    setTimeout(() => {
                      document.querySelector(".map-address").scrollIntoView({ behavior: "smooth" });
                    }, 100);
















                  }}>{t("Find location (Map)")}</Button>
                </div>
              </div>

              {/* Location */}
              {/* <div className="row mb-4">
                <div className="col-2 align-content-center">
                  <p className="m-0">
                    <strong>{t("Location")}</strong>
                  </p>
                </div>
                <div className="col-6 d-flex">
                  <Button
                    color="primary"
                    className="text-nowrap"
                    onClick={() => {
                      getLocation();
                    }}
                  >
                    {locationLoading ? t("Loading...") : t("Find Location")}
                  </Button>
                  <input
                    className="form-control ms-3"
                    placeholder={t("Add location")}
                    value={individual.location}
                    onChange={(e) => {
                      updateServerParams(e.target.value, "location");
                    }}
                  />
                </div>
              </div> */}
              <input
                  className="form-control ms-3"
                  placeholder={t("Add location")}
                  value={individual.location || headOfFamily?.location}
                  hidden={true}
                  onChange={(e) => {
                    updateServerParams(e.target.value, "location");
                  }}
                />

              {/* Family | THIS FIELD IS HIDDEN TEMPORARILY */}
              {/* <div className="row mb-4">
                <div className="col-2 align-content-center">
                  <p className="m-0"><strong>Family</strong></p>
                </div>
                <div className="col-10 ">
                  <Autocomplete
                    name="Family"
                    searchParam="title"
                    list={familyList}
                    placeholder={selectedFamily.title || "Select a family"}
                    selectedObject={(value) => {
                      updateServerParams(value, "family");
                      setSelectedFamily(value);
                    }}
                  />
                </div>
              </div> */}

              {/* Status */}
              <div className="row mb-4">
                <div className="col-2 align-content-center">
                  <p className="m-0">
                    <strong>{t("Status")}</strong>
                  </p>
                </div>
                <div className="col-4">
                  <select
                    className={`form-control form-select ${isFormSubmitted && !individual.gender ? 'is-invalid' : ''}`}
                    value={individual.status}
                    onChange={(e) => {
                      updateServerParams(e.target.value, "status");
                    }}
                    required={true}

                  >
                    <option value="alive">{t("Alive")}</option>
                    <option value="dead">{t("Dead")}</option>
                    <option value="lost">{t("Lost")}</option>
                  </select>
                </div>
              </div>

              {/* Is Head Of Family */}
              <div className="row mb-4">
                <div className="col-2 align-content-center">
                  <p className="m-0">
                    <strong>{t("Is head of family")}</strong>
                  </p>
                </div>
                <div className="col-4">
                  <div className="form-check form-switch">
                    <input
                      className="form-check-input"
                      checked={individual.is_head_of_family}
                      type="checkbox"
                      role="switch"
                      onChange={(e) => {
                        updateServerParams(
                          e.target.checked,
                          "is_head_of_family"
                        );
                      }}
                      required={true}

                    />
                  </div>
                </div>
              </div>

              {/* Is in the same address */}
              <div className="row mb-4">
                <div className="col-2 align-content-center">
                  <p className="m-0">
                    <strong>{t("Stay with family?")}</strong>
                  </p>
                </div>
                <div className="col-4">
                  <div className="form-check form-switch">
                    <input
                      className="form-check-input"
                      checked={individual.stay_with_family}
                      type="checkbox"
                      role="switch"
                      onChange={(e) => {
                        updateServerParams(
                          e.target.checked,
                          "stay_with_family"
                        );
                      }}
                      required={true}

                    />
                  </div>
                </div>
              </div>

              {/* Is Draft */}
              <div className="row mb-4">
                <div className="col-2 align-content-center">
                  <p className="m-0">
                    <strong>{t("Is draft")}</strong>
                  </p>
                </div>
                <div className="col-4">
                  <div className="form-check">
                    <input
                      className="form-check-input"
                      type="checkbox"
                      checked={individual.is_draft}
                      onChange={(e) => {
                        updateServerParams(e.target.checked, "is_draft");
                      }}
                      required={true}

                    />
                  </div>
                </div>
              </div>

              {/* Notes */}
              <div className="row mb-4">
                <div className="col-2 align-content-center">
                  <p className="m-0">
                    <strong>{t("Notes")}</strong>
                  </p>
                </div>
                <div className="col-4">
                  <textarea
                    className="form-control"
                    maxLength="225"
                    rows="5"
                    placeholder={t("Add Extra Info")}
                    value={individual.notes}
                    onChange={(e) => {
                      updateServerParams(e.target.value, "notes");
                    }}
                  ></textarea>
                </div>
              </div>

              {/* Actions */}
              <Button
                color="primary"
                disabled={isSaving}
                onClick={() => {
                  saveIndividual();
                }}
              >
              {t("Save")}
              </Button>
              {dataSaved && (
                <div className="alert alert-success mt-3" role="alert">
                  {t("Individual saved successfully!")}
                </div>
              )}
            </div>
          </div>
          <div className="map-address" style={{display: showMap ? "block" : "none", textAlign: "center"}}>
            <MapAddress 
              apiKey="AIzaSyBGXK2AgiTATdXXSL4rGi1gCSYx8EOIDrw" 
              defaultCenter={{ lat: 40.730610, lng: -73.935242 }} 
              onLocationChange={handleLocationChange}
            />
            <Button color="danger" className="mt-2" onClick={() => {
              setShowMap(false);
              setTimeout(() => {
                document.querySelector(".form-address").scrollIntoView({ behavior: "smooth" });
              }, 100);
            }}>{t("Close Map")}</Button>
          </div>
        </Container>
      </div>
    </React.Fragment>
  );
}

export default withTranslation()(CreateOrEditIndividual);