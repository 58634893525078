const setErrorFn = (e, setErrorOccurred, setError) => {
    setErrorOccurred(true)
    const errors = [];
    Object.keys(e.response.data).forEach(x => {
        errors.push(`${x.toUpperCase()}: ${e.response.data[x]}`);
    });
    setError(errors.join(", "));
}

const validateRequiredFields = () => {
    const requiredFields = document.querySelectorAll("[required]");
    const isInvalidForm = Array.from(requiredFields).some((field) => !field.value);

    requiredFields.forEach((field) => {
      if (!field.value) {
        field.classList.add("is-invalid");
      } else {
        field.classList.remove("is-invalid");
      }
    });

    return isInvalidForm;
}

export { setErrorFn, validateRequiredFields };