import PropTypes from 'prop-types';
import React, { useState, useEffect, useContext } from "react";
import { useTranslation } from 'react-i18next';
import { validateRequiredFields, setErrorFn } from '../Utility/Functions';

import {
  Container,
  Row,
  Col,
  Card,
  Alert,
  CardBody,
  Button,
  Form,
  FormFeedback,
  Label,
  Input
} from "reactstrap";

// Redux and actions
import { connect, useDispatch } from "react-redux";
import withRouter from 'components/Common/withRouter';
import Breadcrumb from "../../components/Common/Breadcrumb";
import { editProfile, resetProfileFlag } from "../../store/actions";
import AuthContext from "context/AuthContext";
import avatar from "../../assets/images/users/user-4.jpg";
import { useFormik } from "formik";
import * as Yup from "yup";


const UserProfile = props => {
  const dispatch = useDispatch();
  const { logout, user } = useContext(AuthContext);
  const [email, setemail] = useState("");
  const [name, setname] = useState("");
  const [idx, setidx] = useState(1);
  const [userType, setUserType] = useState("");  

  //Password change status  
  const [oldPassword, setOldPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [errors, setErrors] = useState({});

 // Function to change the password
  const handleChangePassword = () => {
    const fieldsToValidate = {
      oldPassword: oldPassword,
      newPassword: newPassword,
      confirmPassword: confirmPassword,
    };

    const requiredFieldsError = validateRequiredFields(fieldsToValidate);
    if (requiredFieldsError) {
      setErrors(requiredFieldsError);
      return;
    }

    if (newPassword !== confirmPassword) {
      setErrorFn("confirmPassword", "Passwords do not match", setErrors);
      return;
    }

    console.log("Old Password:", oldPassword);
    console.log("New Password:", newPassword);
    alert("Password changed successfully");  };

  useEffect(() => {
    if (localStorage.getItem("authUser")) {
      const obj = JSON.parse(localStorage.getItem("authUser"));
      if (process.env.REACT_APP_DEFAULTAUTH === "firebase") {
        setname(obj.displayName);
        setemail(obj.email);
        setidx(obj.uid || 1);
      } else if (
        process.env.REACT_APP_DEFAULTAUTH === "fake" ||
        process.env.REACT_APP_DEFAULTAUTH === "jwt"
      ) {
        setname(obj.username);
        setemail(obj.email);
        setidx(obj.uid || 1);
      }
    }

    const storedUserType = localStorage.getItem("userType");
    if (storedUserType) {
      setUserType(storedUserType);  
    }

    setTimeout(() => {
      props.resetProfileFlag();
    }, 3000);
  }, [props.success]);   
  
  const { t } = useTranslation();

  const handleLogout = () => {
    logout();   
  };

  const validation = useFormik({
    enableReinitialize: true,
    initialValues: {
      username: name || '',
      idx: idx || '',
    },
    validationSchema: Yup.object({
      username: Yup.string().required(t("Please Enter Your User Name")),
    }),
    onSubmit: (values) => {
      dispatch(editProfile(values)); 
    }
  });

  document.title = t("Profile | tijuana Dashboard");

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Breadcrumb title={t("tijuana")} breadcrumbItem={t("Profile")} />

          <Row>
            <Col lg="12">
              {props.error && (
                <Alert color="danger">{props.error}</Alert>
              )}
              {props.success && (
                <Alert color="success">{props.success}</Alert>
              )}

              <Card>
                <CardBody>
                  <div className="d-flex">
                    <div className="mx-3">
                      <img
                        src={avatar}
                        alt=""
                        className="avatar-md rounded-circle img-thumbnail"
                      />
                    </div>
                    <div className="align-self-center flex-1">
                      <div className="text-muted">
                        <h5>{name}</h5>
                        <p className="mb-1">{email}</p>
                        <p className="mb-1">{t("Full Name")}: {name}</p>
                        <p className="mb-0">
                          {t("User Name")}: {user?.username} {userType ? `${userType}` : ""}
                        </p>
                      </div>
                    </div>
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>

          <h4 className="card-title mb-4">{t("Change Password")}</h4>
          <Card>
            <CardBody>
              <Form className="form-horizontal">
                <Row>
                  <Col md={4}>
                    <div className="form-group">
                      <Label className="form-label">{t("Old Password")}</Label>
                      <Input
                        type="password"
                        name="oldPassword"
                        className="form-control"
                        placeholder={t("Enter Old Password")}
                        value={oldPassword}
                        onChange={(e) => setOldPassword(e.target.value)}
                      />
                    </div>
                  </Col>

                  <Col md={4}>
                    <div className="form-group">
                      <Label className="form-label">{t("New Password")}</Label>
                      <Input
                        type="password"
                        name="newPassword"
                        className="form-control"
                        placeholder={t("Enter New Password")}
                        value={newPassword}
                        onChange={(e) => setNewPassword(e.target.value)}
                      />
                    </div>
                  </Col>

                  <Col md={4}>
                    <div className="form-group">
                      <Label className="form-label">{t("Confirm New Password")}</Label>
                      <Input
                        type="password"
                        name="confirmPassword"
                        className="form-control"
                        placeholder={t("Confirm New Password")}
                        value={confirmPassword}
                        onChange={(e) => setConfirmPassword(e.target.value)}
                      />
                    </div>
                  </Col>
                </Row>

                <div className="text-center mt-4">
                  <Button
                    type="button"
                    color="primary"
                    onClick={handleChangePassword}
                  >
                    {t("Change Password")}
                  </Button>
                </div>
              </Form>
            </CardBody>
          </Card>

          <div className="text-center mt-4">
            <Button type="button" color="danger" onClick={handleLogout}>
              {t("Logout")}
            </Button>
          </div>
        </Container>
      </div>
    </React.Fragment>
  );
};

UserProfile.propTypes = {
  editProfile: PropTypes.func,
  error: PropTypes.any,
  success: PropTypes.any
};

const mapStatetoProps = state => {
  const { error, success } = state.Profile;
  return { error, success };
};

export default withRouter(
  connect(mapStatetoProps, { editProfile, resetProfileFlag })(UserProfile)
);
