import React, {useEffect, useState} from "react"
import instance from "base_url"
import {Link} from "react-router-dom"
import {Button, Col, Container, Row} from "reactstrap"
import moment from "moment/moment"
import { withTranslation, useTranslation } from "react-i18next"; 


 function Visits({ t }) {
    const { i18n } = useTranslation();
    const isEmployee = localStorage.getItem("userType") === "employee"
    const [visits, setVisits] = useState([])
    const [isLoading, setIsLoading] = useState(true)
    const [errorOccurred, setErrorOccurred] = useState(false)

    // Get Visits
    const getVisits = async () => {
        setIsLoading(true)
        try {
            const {data} = await instance.get("/visits/")
            setVisits(data.results)
            setIsLoading(false)
            setErrorOccurred(false)
        } catch (e) {
            setErrorOccurred(true)
            console.error("ERROR: ", e)
        }
    }
    useEffect(() => {
        moment.locale(i18n.language); 
    }, [i18n.language]);
    useEffect(() => {
        getVisits()
    }, [])

    // Update Visit Status
    const updateVisitStatus = async (visit, status) => {
        const newVisit = {...visit}
        newVisit.visit_status = status
        newVisit.family_id = newVisit.family.id
        try {
            const res = await instance.put(`/visits/${visit.id}/`, newVisit)
            getVisits()
        } catch (e) {
            console.error(e)
        }
    }

    // Handle Actions
    const actions = (visit) => {
        if (isEmployee) {
            if (visit.visit_status === "requested") {
                return <></>
            } else if (visit.visit_status === "pending") {
                return <Link to={`/visit/${visit.id}`} state={visit} className="btn btn-primary btn-sm">

            
                   {t("View details")}
                </Link>
            } else if (visit.visit_status === "draft") {
                return <Link to={`/visit/${visit.id}`} state={visit} className="btn btn-primary btn-sm">
                   {t("View details")}
                </Link>
            } else if (visit.visit_status === "completed") {
                return <Link to={`/visit/${visit.id}`} state={visit} className="btn btn-primary btn-sm">
                    {t("Review")}
                </Link>
            } else if (visit.visit_status === "cancelled") {
                return <Link to={`/visit/${visit.id}`} state={visit} className="btn btn-primary btn-sm">
                   {t("Change volunteer")}
                </Link>
            }
        } else {
            if (visit.visit_status === "requested") {
                return <>
                    <Button onClick={() => {
                        updateVisitStatus(visit, "pending")
                    }} color="primary" size="sm">
                        {t("Accept visit")}
                    </Button>
                    <Button onClick={() => {
                        updateVisitStatus(visit, "cancelled")
                    }} color="secondary" size="sm" className="mx-2">Reject</Button>
                </>
            } else if (visit.visit_status === "pending") {
                return <Link to={`/visit/${visit.id}`} state={visit} className="btn btn-primary btn-sm">
                    {t("Make visit")}
                </Link>
            } else if (visit.visit_status === "draft") {
                return <Link to={`/visit/${visit.id}`} state={visit} className="btn btn-primary btn-sm">
                   {t("Complete visit")}
                </Link>
            } else if (visit.visit_status === "completed") {
                return <Link to={`/visit/${visit.id}`} state={visit} className="btn btn-primary btn-sm">
                   {t("Show")}
                </Link>
            } else if (visit.visit_status === "cancelled") {
                return <></>
            }
        }
    }



    const renderStatusIcon = (status) => {
        switch (status) {
            case "requested":
                return <i className="fa fa-share-square text-info"></i>;
            case "pending":
                return <i className="fas fa-clock text-warning"></i>;
            case "completed":
                return <i className="fas fa-check-circle text-success"></i>;
            case "draft":
                return <i className="fas fa-hourglass-start"></i>;
            case "cancelled":
                return <i className="fas fa-times-circle text-danger"></i>;
            default:
                return <></>;
        }
    }
    
    

    console.log(visits)

    return (
        <React.Fragment>
            <div className="page-content">
                <Container fluid>
                    {errorOccurred ? (
                        <div>
                            <p>{t("An Error Occurred!")}</p>
                            <Button
                                onClick={() => {
                                    getVisits()
                                }}
                            >
                               {t("Try again")}
                            </Button>
                        </div>
                    ) : isLoading ? (
                        <p>{t("Loading...")}.</p>
                    ) : (
                        <div className="page-title-box">
                            <Row className="mb-3">
                                <Col md={8}>
                                    <h6 className="page-title">{t("Visits")}</h6>
                                </Col>

                                <Col md="4">
                                    <div className="float-end d-none d-md-block">
                                        {
                                            isEmployee ?
                                                <Link to="/visit/new" className="btn btn-primary">
                                                    {t("Create Visit")}
                                                </Link>
                                                : <></>
                                        }
                                    </div>
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <div className="table-responsive">
                                        <table className="table table-bordered table-hover">
                                            <thead className="bg-dark">
                                            <tr>
                                                <th scope="col">{t("Volunteer")}</th>
                                                <th scope="col">{t("Individual")}</th>
                                                <th scope="col">{t("Family")}</th>
                                                <th scope="col">{t("Date")}</th>
                                                <th scope="col">{t("Status")}</th>
                                                <th scope="col" className="text-center">{t("Action")}</th>
                                            </tr>
                                            </thead>
                                            <tbody>
                                            {visits.map((visit, index) => (
                                                <tr key={index}>
                                                    <td>{visit.volunteer_name}</td>
                                                    <td>{visit.individual?.name}</td>
                                                    <td>{visit.family?.title}</td>
                                                    <td>{moment(visit.visit_date).format('MMMM D, YYYY, h:mm a')}</td>
                                                    <td>{renderStatusIcon(visit.visit_status)} {t(visit.visit_status)}</td>
                                                    <td className="text-center">{actions(visit)}</td>
                                                </tr>
                                            ))}
                                            </tbody>
                                        </table>
                                    </div>
                                </Col>
                            </Row>
                        </div>
                    )}
                </Container>
            </div>
        </React.Fragment>
    )
}

export default withTranslation()(Visits);