import usFlag from "../assets/images/flags/us.jpg"
import spain from "../assets/images/flags/spain.jpg"
import turkish from "../assets/images/flags/turkish.jpg"
import chinese from "../assets/images/flags/chinese.jpg";
import Ukraine from "../assets/images/flags/Ukraine.jpg"
import Arabic from "../assets/images/flags/Arabic.jpg"



const languages = {
  en: {
    label: "English",
    flag: usFlag,
  },
  ar: {
    label: "Arabic",
    flag: Arabic,
  },
  tr: {
    label: "Turkish",
    flag: turkish,
  },
  sp: {
    label: "Spanish",
    flag: spain,
  },
  // gr: {
  //   label: "German",
  //   flag: germany,
  // },
  // it: {
  //   label: "Italian",
  //   flag: italy,
  // },
  ch: {
    label: "Chinese",
    flag: chinese,
  },

  ua: {
    label: "Ukraine",
    flag: Ukraine, 
  }

}

export default languages
