import React, { useEffect, useState } from "react"
import instance from "base_url"
import { Link } from "react-router-dom"
import { Container, Row, Col, Button } from "reactstrap"
import { withTranslation } from "react-i18next"; 


function Families({ t }) {
  const [families, setFamilies] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [errorOccurred, setErrorOccurred] = useState(false);
  // Get Families
  const getFamilies = async () => {
    setIsLoading(true)
    try {
      const { data } = await instance.get("/families/")
      data.results.forEach(el => {
        const headIndividual = el.individuals.find(
          item => item.is_head_of_family
        )
        el.address = el.individuals.length ? el.individuals[0].address : null
        el.head_name = headIndividual
          ? `${headIndividual.first_name} ${headIndividual.last_name}`
          : "-"
      })
      setFamilies(data.results)
      setIsLoading(false)
      setErrorOccurred(false)
    } catch (e) {
      setErrorOccurred(true)
      console.error("ERROR: ", e)
    }
  }

  useEffect(() => {
    getFamilies()
  }, [])

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          {errorOccurred ? 
          <div>
            <p>{t("An Error Occurred!")}</p> 
            <Button onClick={()=>{getFamilies()}}>{t("Try again")}</Button> 
          </div>
          :
          isLoading ?
          <p>{t("Loading")}...</p>
          :
          <div className="page-title-box">
            <Row className="align-items-center mb-3">
              <Col md={8}>
                <h6 className="page-title">{t("Families")}</h6> 
              </Col>

              <Col md="4">
                <div className="float-end d-none d-md-block">
                  <Link to="/individual/new" className="btn btn-primary">
                   {t("Create Family")}
                  </Link> 
                </div>
              </Col>
            </Row>
            <Row>
              <Col>
                <div className="table-responsive">
                  <table className="table table-bordered table-hover">
                    <thead className="bg-dark">
                      <tr>
                        <th scope="col">{t("Title")}</th> 
                        <th scope="col">{t("Head Name")}</th>
                        <th scope="col">{t("Address")}</th>
                        <th scope="col" className="text-center">{t("Action")}</th>
                      </tr>
                    </thead>
                    <tbody>
                      {
                        families.map((family, index)=>(
                          <tr key={index}>
                            <td>{family.title}</td>
                            <td>{family.head_name}</td>
                            <td>{family.address}</td>
                            <td className="text-center"><Link to={`/family/${family.id}`} state={family} className='btn btn-sm btn-primary'>{t("Details")} </Link></td>
                          </tr>
                        ))
                      }
                    </tbody>
                  </table>
                </div>
              </Col>
            </Row>
          </div>
          }
        </Container>
      </div>
    </React.Fragment>
  )
}

export default withTranslation()(Families);