import React from "react";

// Profile
import UserProfile from "../pages/Authentication/user-profile";

// Authentication related pages
import Login from "../pages/Authentication/Login";
import Logout from "../pages/Authentication/Logout";

//Utility
import Pages404 from "../pages/Utility/pages-404";
import Pages500 from "../pages/Utility/pages-500";

// Dashboard
import Dashboard from "../pages/Dashboard";
// Visits
import Visits from "pages/Visits";
import VisitDetails from "pages/Visits/visitDetails";
import CreateVisit from "pages/Visits/createVisit";
// Volunteer
import Volunteers from "pages/Volunteers";
import CreateOrEditVolunteer from "pages/Volunteers/CreateOrEditVolunteer";
// Families
import Families from "pages/Families";
import FamilyDetails from "pages/Families/familyDetails";
// import CreateFamily from "pages/Families/createFamily";
// Individuals
import Individuals from "pages/Individuals";
import CreateOrEditIndividual from "pages/Individuals/createOrEditIndividual";
// Supports
import SupportTypes from "pages/Support/supportTypes";
import SupportEvents from "pages/Support/supportEvents";
import CreateSupport from "pages/Support/CreateSupport";
import SupportDetails from "pages/Support/SupportDetails";
import CreateSupportType from "pages/Support/CreateSupportType";
import SupportCriteria from "pages/Support/SupportCriteria";
import CreateSupportCriteria from "pages/Support/CreateSupportCriteria";
import SupportCriteriaDetails from "pages/Support/SupportCriteriaDetails";

// support events
import CreateSupportEvent from "pages/Support/CreateSupportEvent";
import EditSupportEvent from "pages/Support/EditSupportEvent";
import SupportEventDetails from "pages/Support/SupportEventDetails";

const userRoutes = [
  { path: "/dashboard", component: <Dashboard /> },
  // Visit
  { path: "/visits", component: <Visits /> },
  { path: "/visit/:id", component: <VisitDetails /> },
  { path: "/visit/new", component: <CreateVisit /> },

  // Individuals
  { path: "/individuals", component: <Individuals /> },
  // { path: "/individual-details", component: <IndividualDetails /> },
  { path: "/individual/:id", component: <CreateOrEditIndividual /> },

  // Families
  { path: "/families", component: <Families /> },
  { path: "/family/:id", component: <FamilyDetails /> },
  // { path: "/create-family", component: <CreateFamily /> },

  // Volunteers
  { path: "/volunteers", component: <Volunteers /> },
  { path: "/volunteer/:id", component: <CreateOrEditVolunteer /> },

  // Supports
  // { path: "/supports", component: <Supports /> },
  { path: "/create-support", component: <CreateSupport /> },
  { path: "/support/:id", component: <SupportDetails /> },
  { path: "/support-types", component: <SupportTypes /> },
  { path: "/support-type/:id", component: <CreateSupportType /> },
  { path: "/create-support-type", component: <CreateSupportType /> },

  { path: "/events", component: <SupportEvents /> },
  { path: "/events/new", component: <CreateSupportEvent /> },
  { path: "/event/:id/edit", component: <EditSupportEvent /> },
  { path: "/event/:id", component: <SupportEventDetails /> },

  { path: "/support-criteria", component: <SupportCriteria /> },
  { path: "/support-criteria/new", component: <CreateSupportCriteria /> },
  { path: "/support-criteria/:id", component: <SupportCriteriaDetails /> },

  // //profile
  { path: "/profile", component: <UserProfile /> },

  // this route should be at the end of all other routes
  { path: "/", component: <Dashboard /> },
];

const authRoutes = [
  { path: "/logout", component: <Logout /> },
  { path: "/login", component: <Login /> },
  { path: "/pages-404", component: <Pages404 /> },
  { path: "/pages-500", component: <Pages500 /> },
];

export { userRoutes, authRoutes };
