import React, { useEffect, useState } from "react"
import instance from "base_url"
import { Link } from "react-router-dom"
import { Container, Row, Col, Button } from "reactstrap"
import { set } from "lodash";
import { useTranslation } from "react-i18next";

export default function SupportEvents() {
  const { t } = useTranslation();
  const [events, setEvents] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [errorOccurred, setErrorOccurred] = useState(false);

  const getEvents = () => {
    setIsLoading(true)
    instance.get("/event/")
    .then((res)=>{
      setEvents(res.data.results)
      setIsLoading(false)
    })
    .catch((err)=>{
      setIsLoading(false)
      setErrorOccurred(true)
    })
  }

  useEffect(() => {
    getEvents()
  }, [])

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          {errorOccurred ? 
          <div>
            <p>{t("An Error Occurred!")}</p>
            <Button onClick={()=>{getEvents()}}>{t("Try again")}</Button>
          </div>
          :
          isLoading ?
          <p>{t("Loading...")}</p>
          :
          <div className="page-title-box">
            <Row className="align-items-center mb-3">
              <Col md={8}>
                <h6 className="page-title">{t("Events")}</h6>
              </Col>

              <Col md="4">
                <div className="float-end d-none d-md-block">
                  <Link to="/events/new" className="btn btn-primary">
                  {t("Create Support Event")}
                  </Link>
                </div>
              </Col>
            </Row>
            <Row>
              <Col>
                <div className="table-responsive">
                  <table className="table table-bordered table-hover">
                    <thead className="bg-dark">
                      <tr>
                        <th>{t("Name")}</th>
                        <th>{t("Support Type")}</th>
                        <th>{t("Budget")}</th>
                        <th>{t("Number of Families")}</th>
                        <th>{t("Event Days")}</th>
                      </tr>
                    </thead>
                    <tbody>
                      {
                        events.map((sc, index)=>(
                          <tr key={index}>
                            <td>{sc.name}</td>
                            <td>{sc.support_type.name}</td>
                            <td>{sc.budget}</td>
                            <td>{sc.number_of_families}</td>
                            <td>{sc.event_days}</td>
                            <td className="text-center">
                              <Link to={`/event/${sc.id}`} state={sc} className='btn btn-sm btn-primary'>{t('Show')}</Link> 
                              <Link style={{marginLeft: "20px"}} to={`/event/${sc.id}/edit`} state={sc} className='btn btn-sm btn-primary'>{t('edit')}</Link></td>
                          </tr>
                        ))
                      }
                    </tbody>
                  </table>
                </div>
              </Col>
            </Row>
          </div>
          }
        </Container>
      </div>
    </React.Fragment>
  )
}
