import instance from "base_url";
import React, { useEffect, useState } from "react";
import { useParams, Link } from "react-router-dom";
import { Container, Row, Col } from "reactstrap";
import { useTranslation } from "react-i18next";
import Accordion from 'react-bootstrap/Accordion';
import { useAccordionButton } from 'react-bootstrap/AccordionButton';
import { MEDIA_URL } from "configs";

// Custom Accordion Toggle button
function CustomToggle({ children, eventKey }) {
  const decoratedOnClick = useAccordionButton(eventKey);

  return (
    <button
      type="button"
      className="btn btn-primary mb-1"
      onClick={decoratedOnClick}
    >
      {children}
    </button>
  );
}

export default function FamilyDetails() {
  const { id } = useParams();
  const [family, setFamily] = useState({});
  const { t } = useTranslation();
  const visitId = new URLSearchParams(window.location.search).get("visit");

  const getFamily = async () => {
    try {
      const { data } = await instance.get(`/families/${id}/`);
      setFamily(data);
    } catch (e) {
      console.error(e);
    }
  };

  useEffect(() => {
    getFamily();
  }, [id]);



  console.log(family)

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <div className="page-title-box">
            <Row className="align-items-center mb-3">
              <Col md={8}>
                <h6 className="page-title">{family.title}</h6>
              </Col>


              <Col md="4">
                <div className="d-flex justify-content-end align-items-center">
                 
                  {/* Add New Individual Button */}
                  <Link  
                    to={`/individual/new?family=${family.id}${visitId ? `&visit=${visitId}` : ""}`}
                    className="btn btn-primary mb-4">
                    {t("Add New Individual")}
                  </Link>
                </div>
              </Col>
            </Row>

            <Row>
              <Col>

               {/*  family tree btn */}
            
             <Accordion defaultActiveKey="0" className="mb-3">
              <Accordion.Item eventKey="0">
                <Accordion.Header>{t("Family Tree")}</Accordion.Header>
                <Accordion.Body>
                <img src={`${MEDIA_URL}genogram/${family.id}.png`} alt="Family Tree" />
                </Accordion.Body>
              </Accordion.Item>
            </Accordion>

                <div className="table-responsive">
                  {/* Table with sortable columns */}
                  <table className="table table-bordered table-hover">
                    <thead className="bg-dark text-white">
                      <tr>
                        <th scope="col">{t("Name")}</th>
                        <th scope="col">{t("Phone No.")}</th>
                        <th scope="col">{t("Gender")}</th>
                        <th scope="col">{t("Address")}</th>
                        <th scope="col">{t("Status")}</th>
                        <th scope="col" className="text-center">
                          {t("Action")}
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {family?.individuals?.map((item, index) => (
                        <tr key={index}>
                          <td>{item.name}</td>
                          <td>
                            {item.mobile_number} -{" "}
                            <a href={`tel:${item.mobile_number}`}>{t("Call")}</a>
                          </td>
                          <td>{t(item.gender)}</td>
                          <td>
                            {t("Address")}{" "}
                            <a
                              target="_blank"
                              rel="noopener noreferrer"
                              href={`https://www.google.com/maps?q=${
                                item.location.split(",")[0]
                              },${item.location.split(",")[1]}`}
                            >
                              {t("see on map")}
                            </a>
                          </td>
                          <td style={{color: item.status === 'alive' ? "green" : item.status === "dead" ? "red" : "black"}}>
                            {t(item.status)}
                          </td>
                          <td className="text-center">
                            <Link
                              to={`/individual/${item.id}?family=${family.id}${visitId ? `&visit=${visitId}` : ""}`}
                              className={`btn btn-sm btn-${item.is_draft ? "warning" : "primary"}`}
                            >
                              {t("Details")} {item.is_draft ? `(${t("Draft")})` : ""}
                            </Link>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </Col>
            </Row>
          </div>
        </Container>
      </div>
    </React.Fragment>
  );
}
