import React, { useState, useEffect } from "react"
import instance from "base_url"
import { Link } from "react-router-dom"
import { Container, Row, Col, Button } from "reactstrap"
import { useTranslation } from "react-i18next";



export default function Volunteers() {
  const { t } = useTranslation();
  const [volunteers, setVolunteers] = useState([])
  const [isLoading, setIsLoading] = useState(true)
  const [errorOccurred, setErrorOccurred] = useState(false)

  // Get Volunteers
  const getVolunteers = async () => {
    setIsLoading(true)
    try {
      const { data } = await instance.get("/volunteers/")
      setVolunteers(data.results)
      setIsLoading(false)
      setErrorOccurred(false)
    } catch (e) {
      setErrorOccurred(true)
      console.error("ERROR: ", e)
    }
  }

  useEffect(() => {
    getVolunteers()
  }, [])

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          {errorOccurred ? (
            <div>
              <p>{t("An Error Occurred!")}</p>
              <Button
                onClick={() => {
                  getVolunteers()
                }}
              >
                {t("Try again")}
              </Button>
            </div>
          ) : isLoading ? (
            <p>{t("Loading...")}</p>
          ) : (
            <div className="page-title-box">
              <Row className="align-items-center mb-3">
                <Col md={8}>
                  <h6 className="page-title">{t("Volunteers")}</h6>
                </Col>

                <Col md="4">
                  <div className="float-end d-none d-md-block">
                    <Link to="/volunteer/new" className="btn btn-primary">
                    {t("Create Volunteer")}
                    </Link>
                  </div>
                </Col>
              </Row>
              <Row>
                <Col>
                  <div className="table-responsive">
                    <table className="table table-bordered table-hover">
                      <thead className="bg-dark">
                        <tr>
                          <th scope="col">{t("Name")}</th>
                          <th scope="col">{t("Manager")}</th>
                          <th scope="col">{t("Position")}</th>
                          <th scope="col" className="text-center">{t("Action")}</th>
                        </tr>
                      </thead>
                      <tbody>
                        {volunteers.map((volunteer, index) => (
                          <tr key={index}>
                            <td>{volunteer.individual?.name}</td>
                            <td>{volunteer.manager?.name}</td>
                            <td>{volunteer.position}</td>
                            <td className="text-center">
                              <Link
                                to={`/volunteer/${volunteer.id}`}
                                className="btn btn-sm btn-primary"
                              >
                               {t("Details")}
                              </Link>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                </Col>
              </Row>
            </div>
          )}
        </Container>
      </div>
    </React.Fragment>
  )
}
